import React from "react";
import ExpenseItem from "./ExpenseItem";
import "./ExpensesList.css";

const expensesList = (props) => {
  if (props.expenses.length === 0) {
    return <h2 className="expenses-list__fallback">No expenses found.</h2>;
  }

  return (
    <ul className="expenses-list">
      {props.expenses.map((expense) => {
        return <ExpenseItem key={expense.id} {...expense} />;
      })}
    </ul>
  );
};

export default expensesList;
