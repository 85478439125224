import React from "react";

const NewExpenseButton = () => {
  return (
    <div className="new-expense__control">
      <button>New Expense</button>
    </div>
  );
};

export default NewExpenseButton;
