import "./ExpenseDate.css";

function ExpenseDate(props) {
  const date = new Date(props.date);
  const day = date.toLocaleDateString("id-ID", { day: "2-digit" }),
    month = date.toLocaleDateString("id-ID", { month: "long" }),
    year = date.toLocaleDateString("id-ID", { year: "numeric" });

  return (
    <div className="expense-date">
      <div className="expense-date__day">{day}</div>
      <div className="expense-date__month">{month}</div>
      <div className="expense-date__year">{year}</div>
    </div>
  );
}

export default ExpenseDate;
