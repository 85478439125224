import React, { useState, useEffect } from "react";
import "./App.css";
import Expenses from "./components/Expenses/Expenses";
import NewExpense from "./components/NewExpenses/NewExpenses";
function App() {
  const [expenses, setExpenses] = useState([
    {
      id: "e1",
      title: "Toilet Paper",
      amount: 94.12,
      date: [2020, 7, 14],
    },
    { id: "e2", title: "New TV", amount: 799.49, date: [2021, 2, 12] },
    {
      id: "e3",
      title: "Car Insurance",
      amount: 294.67,
      date: [2021, 2, 28],
    },
    {
      id: "e4",
      title: "New Desk (Wooden)",
      amount: 450,
      date: [2021, 5, 12],
    },
  ]);
  const [newExpenses, setNewExpenses] = useState([]);
  const handleAddExpense = (expense) => {
    setExpenses((prevState) => {
      return [expense, ...prevState];
    });
    setNewExpenses((prevState) => {
      return [expense, ...prevState];
    });
  };

  const handleFilterExpenses = (year) => {
    if (year === "") {
      setNewExpenses(expenses);
    } else {
      setNewExpenses(
        expenses.filter((expense) => {
          return expense.date[0] === parseInt(year);
        })
      );
    }
  };

  useEffect(() => {
    handleFilterExpenses("");
  }, []);

  return (
    <div className="root">
      <NewExpense onAddExpense={handleAddExpense} />
      <Expenses
        expenses={newExpenses}
        onFilterExpenses={handleFilterExpenses}
      />
    </div>
  );
}

export default App;
