import React, { useState } from "react";
import "./NewExpenses.css";
import ExpenseForm from "./ExpenseForm";
import NewExpenseButton from "./NewExpensesButton";
const NewExpense = (props) => {
  const [expand, setExpand] = useState(false);
  const handleSaveExpense = (expense) => {
    const expenseData = {
      ...expense,
      id: Math.random().toString(),
    };
    props.onAddExpense(expenseData);
    console.log(expenseData);
  };
  const handleAddExpense = () => {
    setExpand(!expand);
  };

  return (
    <div className="new-expense">
      {expand ? (
        <ExpenseForm
          onSaveExpense={handleSaveExpense}
          onCancel={handleAddExpense}
        />
      ) : (
        <div onClick={handleAddExpense}>
          <NewExpenseButton />
        </div>
      )}
    </div>
  );
};

export default NewExpense;
