import React from "react";
import "./Expenses.css";
import Card from "./Card";
import ExpensesFilter from "./ExpensesFilter";
import ExpensesList from "./ExpensesList";

function Expenses(props) {
  const handleFilter = (year) => {
    props.onFilterExpenses(year);
  };
  return (
    <div>
      <Card className="expenses">
        <ExpensesFilter onFilterChange={handleFilter} />
        <ExpensesList expenses={props.expenses} />
      </Card>
    </div>
  );
}

export default Expenses;
